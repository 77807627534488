import React, { useState } from "react";
import { Col } from "react-bootstrap";
import contactStyle from "../../components/non-panels/ContactForm.module.scss";
import ReactMarkdown from "react-markdown/with-html";
import { LinkRenderer } from "../../utils/utilities";
import Image from "gatsby-image";
import { graphql } from "gatsby";

//components
import PanelFoundation from "../../components/panels/PanelFoundation";
import HubSpotForm from "../../components/non-panels/forms/HubSpotForm";
import Hero from "../../components/panels/Hero";
import ComponentHeader from "../../components/panels/ComponentHeader";
import SwitchComponent from "../../components/panels/Switch";
import useSetPageData from "../../utils/useSetPageData";
import ListItem from "../../components/panels/ListItem";
import ListItem2 from "../../components/panels/ListItem2";
import SEO from "../../components/seo";

// images

// import Production_Technology_Integration_Maintenance from "../../images/technology-integration/Production_Technology_Integration_Maintenance.png";
// import Technology_Integration_Cloud_Automation from "../../images/technology-integration/Technology_Integration_Cloud_Automation.png";
// import Technology_Integration_Enhancement_Services from "../../images/technology-integration/Technology_Integration_Enhancement_Services.png";
import NITCO_1 from "../../images/rpa-as-a-service/NITCO-1.jpg";
import NITCO_2 from "../../images/rpa-as-a-service/NITCO-2.jpg";
// import Technology_Integration_maintenance_dashboard from "../../images/technology-integration/Technology_Integration_maintenance_dashboard.png";
// import Technology_Integration_Maintenance_Patches from "../../images/technology-integration/Technology_Integration_Maintenance_Patches.png";
// import Technology_Integration_Monitoring_integration from "../../images/technology-integration/Technology_Integration_Monitoring_integration.png";
// import Technology_Integration_On_Prem from "../../images/technology-integration/Technology_Integration_On_Prem.png";
// import Technology_Integration_Production_Support from "../../images/technology-integration/Technology_Integration_Production_Support.png";
// import Technology_Integration_Reporting_issues from "../../images/technology-integration/Technology_Integration_Reporting_issues.png";
// import Technology_Integration_Working_collaboratively from "../../images/technology-integration/Technology_Integration_Working_collaboratively.png";
import rpaIcon from "../../images/manage-rpa/icon.png";
//styles
import s from "./rpa-as-a-service.module.scss";

export const BlogSubscribeFormReplacementMessage = () => (
  <div
    className={contactStyle.thanks}
    style={{
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center"
    }}
  >
    <h5
      style={{
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center"
      }}
    >
      Thank You!
    </h5>
    <p style={{ textAlign: "center" }}>
      We will reach out to you soon regarding your quote.
    </p>
  </div>
);

const TechnologyIntegrationManagedSupportServices = ({ location, data }) => {
  useSetPageData(location);
  // const { raas_cards } = data.strapiPsRpa;
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const showFormOverlay = value => {
    let form = document.querySelector("#hubSpotFormOverlay");
    if (value) {
      form.style.display = "block";
    } else {
      form.style.display = "none";
    }
  };

  return (
    <>
      <SEO
        {...{
          title: `RPA as a Service (RPAaaS)`,
          description:
            "NITCO offers RPA as a Service (RPAaaS) with capabilities designed to meet the needs of small to mid-sized organizations, organizations that are new to digital automation or do not have an IT department."
        }}
      />

      <Hero
        heading={"RPA as a Service (RPAaaS)"}
        iconComponent={<img src={rpaIcon} />}
        // icon={hero[0].service_icon.childImageSharp.fixed}
        // videoImage={hero[0].services_main_image.childImageSharp.fixed.src}
        // videoLink={"/videos/final-consulting.mp4"}
      />
      <ComponentHeader
        rootClassName={`bg-light text-primary`}
        text={`RPA as a Service (RPAaaS) Provider`}
      />
      {/* <div style={{ marginTop: "40px" }}></div> */}
      <SwitchComponent
        evenClassName={`bg-light text-primary pt-5 pb-5`}
        oddClassName={`bg-light text-primary pt-5 pb-5`}
        contents={[
          {
            heading:
              "NITCO’s RPA as a Service is Your Outsourced IT Department for Robotic Process Automation",
            image: <img src={NITCO_1} />,
            // image: <Image fluid={it_overview_image.childImageSharp.fluid} />,
            content: (
              <p>
                Robotic process automation holds great promise for organizations
                of all sizes to become more competitive in the digital
                automation environment. Understandably, some organizations
                simply don’t have the on-site resources (business analysts,
                developers, or project managers) to support their needs for
                digital automation. Smaller organizations or even new
                organizations just starting with digital automations need a
                cost-effective approach to handle software licensing,
                implementations, development, and support. NITCO can help.
              </p>
            )
            // <ReactMarkdown
            //   source={overview_description}
            //   escapeHtml={false}
            //   renderers={{ link: LinkRenderer }}
            // />
          },
          {
            heading: "Big Benefits from RPA as a Service",
            image: <img src={NITCO_2} />,
            // image: <Image fluid={it_overview_image.childImageSharp.fluid} />,
            content: (
              <p>
                Robotic Process Automation, or RPA, is your personal
                assistant—designed to help you perform a wide range of business
                functions. But the capabilities to handle this in-house are
                limited or nonexistent for most organizations. That’s where RPA
                as a Service can come into play. We’ll handle all aspects of
                your RPA) needs—from identifying and assessing automation
                opportunities and building automations, to including the cost of
                licensing and hosting, to ongoing maintenance and support—and
                much more. All for one low monthly fee based on your needs.
              </p>
            )
          }
        ]}
      />

      <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={"What Is RPA as a Service (RPAaaS)?"}
      />
      {/* <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"RPA as a Service"}
        id={"rpa-raas"}
      /> */}

      <PanelFoundation rootClassName={`${s.saas} pb-0`}>
        <div className={`col-sm-12 text-justify`}>
          <ReactMarkdown
            source={
              "RPA as a Service offers the benefits of RPA without the upfront implementation expenses, software licensing and time-consuming infrastructure setup as well as helping you to identify your automation pipeline, building your automations and your on-going support and maintenance. By choosing a Cloud-based RPA as a Service subscription option, you gain an expedited RPA implementation process using a tested and proven RPA infrastructure alongside NITCO's implementation expertise to avoid initial and on-going costs and training requirements. Additionally, our service offers easy scalability and can complement your legacy systems to bring outdated yet vital systems into the digital age."
            }
            // renderers={{ link: LinkRenderer }}
          />
        </div>
      </PanelFoundation>
      {/* <ListItem
        columnsPerRow={3}
        isCard
        // hasCircle
        // circleColor={"white"}
        // circleBorderColor={"var(--primary)"}
        rootClassName={s.saas}
        itemClassName={`${s.card}`}
        contents={raas_cards.map((oc, i) => ({
          image: <Image fixed={oc.card_image.childImageSharp.fixed} />,
          heading: oc.heading,
          content: (
            <ReactMarkdown
              source={oc.content}
              escapeHtml={false}
              renderers={{ link: LinkRenderer }}
            />
          )
        }))}
      /> */}

      <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={"Who Can Benefit from RPA as a Service?"}
      />
      <PanelFoundation rootClassName={`panel-padding`}>
        <Col sm={12}>
          <p>
            NITCO’s RPAaaS solution is designed to provide “white glove”,
            end-to-end digital automation services for:
          </p>
          <ul className={`pb-3 pt-3`}>
            <li>Small to mid-sized organizations with limited budgets</li>
            <li>
              Organizations that are new to digital automation and want to see
              the benefits
            </li>
            <li>Organizations that do not have an IT department</li>
            <li>
              Organizations that want to outsource the management of their
              digital automation services to the experts
            </li>
          </ul>
        </Col>
      </PanelFoundation>

      <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={"Why Choose NITCO for RPA as a Service?"}
      />
      <PanelFoundation rootClassName={`panel-padding`}>
        <Col sm={12}>
          <p>
            Selecting NITCO as your RPA as a Service provider allows you to
            delegate the automations, management and day-to-day support and
            maintenance of your RPAaaS automated processes so you can focus on
            your organization. As an established partner, NITCO can offer
            reliability and availability to manage—and enhance—your automated
            tasks. You’ll benefit from our experience and expertise in robotic
            process automation and our ability to help you identify the tasks
            you can automate to save your business time and money. Our
            consistent and customer-focused approach to issue tracking and
            resolution also means your end users can count on the automations
            they need to maximize their productivity and effectiveness every
            day. Each client’s need is unique, so we work with you up front to
            identify how we can best support you for the short and long term.
            After discussing your specific needs, NITCO will work with your
            staff to outline the specific requirements of our engagement in a
            SOW that documents:
          </p>
          <ul className={`pb-3 pt-3`}>
            <li>The specific services you’ll receive</li>
            <li>Who’s responsible for what—and when</li>
            <li>Expected deliverables</li>
            <li>Monthly support and maintenance costs</li>
          </ul>
          <p>
            That’s the starting point, of course. NITCO understands that your
            needs may change as you grow and as the you scale up your digital
            automation. Our change process is flexible and agile enough to
            support your evolving needs.
          </p>
        </Col>
      </PanelFoundation>

      {/* <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={"Engage with NITCO’s RPA Team Today"}
      />
      <PanelFoundation rootClassName={`panel-padding`}>
        <Col sm={12}>
          <p>
          NITCO is an industry leader with years of experience assisting organizations large and small with their digital automation needs. Contact us today to discuss RPA as a Service to receive a complimentary estimate.
          </p>
        </Col>
      </PanelFoundation>
      <ComponentHeader
        rootClassName={`pt-0`}
        textClassName={`text-primary`}
        text={"Choose From Standard and Premium RPA as a Service plan"}
      />
      <PanelFoundation rootClassName={`panel-padding pb-0`}>
        <Col sm={12}>
              <h3>
            Engage with NITCO’s  Team Today
          </h3> 
          <p>
            NITCO is an industry leader with years of experience assisting
            companies large and small with their  managed support and
            maintenance needs. Contact us today to discuss your  management
            needs and to receive a free estimate.
          </p> 
        </Col>
      </PanelFoundation> */}

      <div className={`${s.pricing} pricing-palden`}>
        <div className="pricing-item">
          <div className="pricing-deco bg-primary">
            <div className="pricing-price">
              <h5 className="pricing-title">Premium</h5>
            </div>
          </div>
          <p style={{ width: "80%", margin: "1rem auto" }}>
            <strong>24/7/365 days</strong>
          </p>
          <ul className="pricing-feature-list">
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>Client
              Portal/Dashboard
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Proactive RPA Monitoring
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Secure Azure Hosting and Management
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Licenses
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              NITCO Automation Center of Excellence
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Complimentary Automation Assessment
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Production RPA Maintenance / Lights-On Incident Support.
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              RPA Maintenance / Production Support
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Enhancements/Process Development (20 hours per Month included)
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Citizen Developer On-boarding & Support (Add on available)
            </li>
          </ul>
          <button
            className="pricing-action"
            onClick={() => showFormOverlay(true)}
          >
            Request a Quote
          </button>
        </div>
        <div className="pricing-item ">
          <div className="pricing-deco bg-primary">
            <div className="pricing-price">
              <h4 className="pricing-title">Standard</h4>
            </div>
          </div>
          <p style={{ width: "80%", margin: "1rem auto" }}>
            <strong>
              US Office Hours, 8a-5p CST,{" "}
              <span style={{ whiteSpace: "nowrap" }}>No Holidays</span>
            </strong>
          </p>
          <ul className="pricing-feature-list">
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>Client
              Portal/Dashboard
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Proactive RPA Monitoring
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Secure Azure Hosting and Management
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Licenses
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              NITCO Automation Center of Excellence
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Complimentary Automation Assessment
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Production RPA Maintenance / Lights-On Incident Support.
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              RPA Maintenance / Production Support
            </li>
          </ul>
          <button
            className="pricing-action"
            onClick={() => showFormOverlay(true)}
          >
            Request a Quote
          </button>
        </div>
      </div>

      <ComponentHeader
        rootClassName={`pt-0`}
        textClassName={`text-primary`}
        text={"Engage with NITCO’s Technology Integration Team Today "}
      />
      <PanelFoundation rootClassName={`panel-padding pb-0`}>
        <Col sm={12}>
          <p>
            NITCO is an industry leader with years of experience assisting
            organizations large and small with their digital automation needs.
            <strong>
              <a href="mailto:YourDigitalTechnologyPartner@NitcoInc.com?subject=Technology%20Integration%20Support%20and%20Maintenance%20Estimate">
                {" "}
                Contact us today{" "}
              </a>
            </strong>
            today to discuss RPA as a Service to receive a complimentary
            estimate.
          </p>
        </Col>
      </PanelFoundation>
      <br />
      <br />
      <br />

      <div id="hubSpotFormOverlay" className={`${s.hubSpotFormOverlay}`}>
        <div className={`${s.hubSpotFormOverlayDiv}`}>
          <div className={`${s.hubSpotFormHeader}`}>
            <span className={`${s.hubSpotFormHeaderDescription}`}>
              Request a new Quote
            </span>
            <span
              className={`${s.hubSpotFormOverlayCloseButton}`}
              onClick={() => {
                showFormOverlay(false);
              }}
            >
              X
            </span>
          </div>
          <HubSpotForm
            formId="c052d59c-c46f-4821-a9d7-a3b5e73f68cb"
            replacementElement={
              <div
                className={"container"}
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <div
                  className={`container`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <BlogSubscribeFormReplacementMessage />
                </div>
                <div
                  className={"container"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <button
                    className="btn btn-primary ml-4"
                    onClick={() => {
                      showFormOverlay(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default TechnologyIntegrationManagedSupportServices;

// export const pageQuery = graphql`
//   {
//     strapiPsRpa {
//       raas_cards {
//         heading
//         content
//         card_image {
//           childImageSharp {
//             fixed(width: 100) {
//               ...GatsbyImageSharpFixed
//             }
//           }
//         }
//       }
//     }
//   }
// `;
